import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
// import SignIn from './pages/SignIn/SignIn';
import Profile from './pages/Profile/Profile';
import Manage from './pages/Manage/Manage'; 
import NewPost from './pages/NewPost/NewPost';
import PostDetail from './pages/PostDetail/PostDetail';
import AboutUs from './pages/AboutUs/AboutUs';
import Chat from './pages/Chat/Chat';
import SingleNewsPage from './pages/SingleNewsPage/SingleNewsPage';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import { createContext, useState, useEffect, useContext } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';

export const ThemeContext = createContext(null);

const Layout = () => {
  const theme = useContext(ThemeContext);

  const formStyles = {
    background: theme === 'light' ? '#ffffff' : '#000000',
    color: theme === 'light' ? '#000000' : '#ffffff',
    filter: theme === "dark" ? "saturate(50%) brightness(70%)" : "none",
  };

  return (
    <div className="md:w-8/12 mx-auto">
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {  
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />, 
      },
      {
        path: "/profile/:id",
        element: <Profile />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/manage/:id",
        element: <Manage />,
      },
      {
        path: "/signout",
        element: <Home />,
      },
      {
        path: "/NewPost",
        element: <NewPost />,
      },
      {
        path: "/PostDetail/:id",
        element: <PostDetail />,
      },
      {
        path: "/AboutUs",
        element: <AboutUs />,
      },
      {
        path: "/Chat",
        element: <Chat />,
      },
      {
        path: "/news/:title/:id",
        element: <SingleNewsPage />,
      },
      {
        path: "/*" ,
        element: <PageNotFound />,
      },
    ],
  },
]);

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);
        
  const ToggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  return (
    <div className={`App ${theme}`}>
      <Helmet>
        <html lang="en" />
        <meta name="description" content="Check news from all around the world." />
        <link rel="canonical" href="https://www.thebinational.com" />
      </Helmet>

      <ThemeContext.Provider value={theme}> 
        <RouterProvider router={router} />
      </ThemeContext.Provider>
    </div>
  );
}

export default App;