import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from "../../components/Navbar/Navbar";
import FooterForSinglePage from '../../components/FooterForSinglePage/FooterForSinglePage';
import { countries as allCountries } from '../../components/Country/country';
import { categories as allCategories } from '../../components/Category/category';
// import { languages as allLanguages } from '../../components/Language/language';

const SingleNewsPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [language, setLanguage] = useState("English");
  const [countries, setCountries] = useState(allCountries.map(country => country.value));
  const [selectedCategories, setSelectedCategories] = useState(allCategories.map(category => category.label));

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://collaborative-citizen-8dfb225eb320.herokuapp.com/api/posts/${id}`);
        setPost(response.data);
      } catch (error) {
        console.error("Failed to fetch post:", error);
      }
    };

    fetchPost();
  }, [id]);

  const handleHeaderClick = () => {
    window.location.href = '/';
  };

  if (!post) return <div>Loading...</div>;

  return (
    <div style={{ position: "relative", overflowY: "auto", maxHeight: "100vh" }}>
      <HelmetProvider>
        <Helmet>
          <html lang={language.toLowerCase().split(' ')[0]} />
          <title>{post.title} - The Binational</title>
          <meta name="description" content={post.description} />
          <link rel="canonical" href={`https://www.thebinational.com/news/${id}`} />
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.description} />
          <meta property="og:url" content={`https://www.thebinational.com/news/${id}`} />
          <meta property="og:type" content="article" />
        </Helmet>
      </HelmetProvider>

      <Navbar onClick={handleHeaderClick} />

      <main style={{ flex: 1, marginTop: "105px", marginBottom:"50px", backgroundColor: "#ffffff", padding: "20px" }}>
        <h1 style={{ fontSize: '24px',color:'rgb(17 24 39)' ,  fontWeight: 'bold', marginBottom: '20px' }}>{post.title}</h1>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <span>Category: {post.tag2}</span>
          <span>Date: {new Date(post.createdAt).toLocaleDateString()}</span>
        </div>
        <p style={{ marginBottom: '20px', color:'rgb(17 24 39)'}}>{post.description}</p>
        {post.FactCheck && post.FactCheck.factuality && (
          <div style={{ backgroundColor: '#f0f0f0', padding: '15px', borderRadius: '5px', marginBottom: '20px' }}>
            <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Fact Check</h2>
            {
              post.FactCheck.factuality && (
                <p>Factuality: {post.FactCheck.factuality.toString()}</p>
              )
            }
            {post.FactCheck.conclusion && (
              <p>Conclusion: {post.FactCheck.conclusion}</p>
            )}
          </div>
        )}
      </main>

      <FooterForSinglePage/>
    </div>
  );
};

export default SingleNewsPage;