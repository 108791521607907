import React from "react";
import { Link } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

const FooterForSinglePage = () => {
  return (
    <div className="footer-container">
      <HelmetProvider>
        <title>Explore Verified News Footer</title>
        <meta name="description" content="Footer component with a link to the main page. Get accurate information on fact checking and truth verification." />
      </HelmetProvider>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktE6v2ELv1oD2f3z6Zj43E1pocBw5aFbhqL9ajKOf"
        crossOrigin="anonymous"
      />
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.9.1/font/bootstrap-icons.min.css"
        rel="stylesheet"
      />
      <div className="fixed-bottom bg-dark position-relative" style={{ height: '8px' }}>
        {/* Conteneur centré */}
        <div className="container d-flex justify-content-center align-items-center">
        </div>
        {/* Bouton Home avec nouveau style */}
        <Link to="/" className="home-button" aria-label="Home">
          <i className="bi bi-house-fill"></i>
        </Link>
      </div>
      <style>
        {`
          .home-button {
            position: fixed;
            bottom: 20px;
            right: 40px;
            background-color: #031f2d;
            color: #ffffff;
            border: 10px solid #031f2d;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s ease;
            z-index: 1000;
            font-size: 2rem;
            padding: 20px;
          }
            .home-button:hover {
            transform: scale(1.2);
          }
        `}
      </style>
    </div>
  );
};

export default FooterForSinglePage;
