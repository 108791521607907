import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import TimeLinePost from "../../components/TimeLinePost/TimeLinePost";
import { countries as allCountries } from '../../components/Country/country';
import { categories as allCategories } from '../../components/Category/category';
import { languages as allLanguages } from '../../components/Language/language';

const Home = () => {
    const [language, setLanguage] = useState(""); // Initialize language state without a default value
    const [countries, setCountries] = useState(allCountries.map(country => country.value));
    const [selectedCategories, setSelectedCategories] = useState(allCategories.map(category => category.label));
    const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility

    useEffect(() => {
        // Function to determine browser language and set state accordingly
        const determineLanguage = () => {
            const browserLanguage = navigator.language.split('-')[0]; // Extracting language code

            // Check if browser language is supported, otherwise fallback to English
            const supportedLanguages = allLanguages.map(language => language.value);
            const languageCodeMap = {
                'en': 'English',
                'fr': 'French',
                'es': 'Spanish',
                'hi': 'Hindi',
                'zh': 'Chinese',
                'ja': 'Japanese',
                'ar': 'Arabic',
                'he': 'Hebrew'
            };
            const matchedLanguage = languageCodeMap[browserLanguage];
            if (matchedLanguage && supportedLanguages.includes(matchedLanguage)) {
                setLanguage(matchedLanguage);
            } else {
                setLanguage('English'); // Fallback to English if browser language is not supported
            }
        };

        determineLanguage(); // Call the function when component mounts
    }, []); // Empty dependency array to run only once when component mounts

    const handleHeaderClick = () => {
        window.location.reload();
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div style={{ position: "relative" }}>
            <HelmetProvider>
                <Helmet>
                    <html lang={language.toLowerCase().split(' ')[0]} />
                    <title>The Binational: Your Source for Global Fact Checking, Unbiased News, and Debunking Fake News</title>
                    <meta name="description" content="Discover the truth behind international conflicts with our advanced fact-checking technology. Our fact checker ensures real-time verification and unbiased reporting. Explore the news confidently." />
                    <link rel="canonical" href="https://www.thebinational.com/" />
                    <meta property="og:title" content="The Binational: Global Fact Checking and Unbiased News" />
                    <meta property="og:description" content="Advanced fact-checking technology for international conflicts. Real-time verification and unbiased reporting." />
                    <meta property="og:image" content="https://www.thebinational.com/og-image.jpg" />
                    <meta property="og:url" content="https://www.thebinational.com/" />
                    <meta property="og:type" content="website" />
                </Helmet>
            </HelmetProvider>
            {showPopup && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '9999' }}>
                    <div style={{ maxWidth: '80vw', padding: '20px', background: 'white', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflowX: 'auto', scrollSnapType: 'x mandatory', position: 'relative' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/popup1.png" alt="Fact-checking process visualization" style={{ width: '200px', margin: '0 5px', borderRadius: '10px' }} />
                            <img src="/popup2.png" alt="Global news coverage illustration" style={{ width: '200px', margin: '0 5px', borderRadius: '10px' }} />
                            <img src="/popup3.png" alt="Unbiased reporting demonstration" style={{ width: '200px', margin: '0 5px', borderRadius: '10px' }} />
                        </div>
                        <button
                            onClick={closePopup}
                            style={{
                                backgroundColor: '#031f2d',
                                color: '#fff',
                                borderRadius: '9999px',
                                border: 'none',
                                padding: '8px 20px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                display: 'block',
                                margin: 'auto', // Center the button horizontally
                                marginTop: '10px', // Add margin to separate button from images
                                position: 'fixed', // Fixed position within the popup container
                                bottom: '20px', // Adjust bottom position as needed
                                left: '50%', // Center horizontally
                                transform: 'translateX(-50%)' // Center horizontally
                            }}
                        >
                            Let's go!
                        </button>
                    </div>
                </div>
            )}
            <div style={{ filter: showPopup ? "blur(5px)" : "none" }}>
                <Navbar onClick={handleHeaderClick} />
                <main style={{ flex: 1, marginTop: "50px", backgroundColor: "#ffffff" }}>
                    <h1 style={{ position: 'absolute', width: '1px', height: '1px', padding: '0', margin: '-1px', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', whiteSpace: 'nowrap', border: '0' }}>
                        The Binational: Global Fact Checking and Unbiased News
                    </h1>
                    <TimeLinePost language={language} countries={countries} selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} />
                </main>
                <Footer
                    onLanguageChange={setLanguage}
                    onCountryChange={setCountries}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    language={language} 
                />
            </div>
        </div>
    );
};

export default Home;