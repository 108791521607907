import React, { useState, useContext } from 'react';
import { ThemeContext } from '../../App';
import CountryFlag from 'react-country-flag';
import { countryMapping } from '../Country/country';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faChevronDown, faChevronUp,faShareSquare, faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import traductions from '../Traduction/traduction'; // Adjust path as necessary
import { Link } from 'react-router-dom';

const Post = ({ post, setData, language }) => {
  const theme = useContext(ThemeContext);
  const [expanded, setExpanded] = useState(false);
  const [buttonColor, setButtonColor] = useState('#031f2d');
  const [showPopup, setShowPopup] = useState(false);
  const [translatedContent, setTranslatedContent] = useState('');

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  // Function to get the countryCode based on the country name
  const getCountryCode = (country) => {
    return countryMapping[country] || ''; // Return empty string if country code not found
  };

  // Function to calculate the time difference and return in minutes or hours
  const calculateTimeDifference = (createdAt) => {
    const createdAtMoment = moment.tz(createdAt, 'YYYY-MM-DD HH:mm:ss ZZ', 'GMT');
    const currentMoment = moment();
    const timeDifferenceInMinutes = currentMoment.diff(createdAtMoment, 'minutes');

    if (timeDifferenceInMinutes >= 60) {
      const timeDifferenceInHours = currentMoment.diff(createdAtMoment, 'hours');
      return `${timeDifferenceInHours} hour${timeDifferenceInHours !== 1 ? 's' : ''} ago`;
    }

    return `${timeDifferenceInMinutes} min${timeDifferenceInMinutes !== 1 ? 's' : ''} ago`;
  };

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        setButtonColor('green');
        setTimeout(() => {
          setButtonColor('#031f2d');
        }, 3000);
      })
      .catch((error) => {
        console.error('Erreur lors de la copie:', error);
      });
  };
  
  

  // Translation function
  const translate = (key) => {
    if (traductions[key] && traductions[key][language]) {
      return traductions[key][language];
    } else {
      console.warn(`Translation for '${key}' not found for language '${language}'`);
      return key; // Fallback to the key itself if not found
    }
  };

  const handleReadArticle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const getGoogleTranslateUrl = (url, targetLang) => {
    // Map your app's language codes to Google Translate language codes if necessary
    const googleLangCode = {
      'en': 'en',
      'fr': 'fr',
      // Add more mappings as needed
    }[targetLang] || 'en';

    return `${post.link}`;
  };

  // Extracting the URL from the evidence field
  let evidenceUrl = null;
  if (post.FactCheck?.evidence && post.FactCheck.evidence.length > 0) {
    for (const item of post.FactCheck.evidence) {
      if (item.startsWith('http')) {
        evidenceUrl = item;
        break;
      }
    }
  }

  return (
    <div
      onClick={handleExpand}
      className={`bg-[#fff] border border-[#031f2d] flex flex-col px-7 py-4 rounded-lg mx-4 mb-2 gap-2 cursor-pointer`}
    >
      <div className="flex items-center justify-between space-x-2">
        <div className="flex items-center space-x-2 flex-wrap">
          {post.country && (
            <div className="flex items-center space-x-2">
              <p className={`text-sm flex items-center ${theme === 'dark' ? 'bg-dark-blue' : 'bg-light-blue'} rounded-full overflow-hidden`} style={{ width: '30px', height: '30px', border: '2px solid #ccc' }}>
                <CountryFlag countryCode={getCountryCode(post.country)} svg countryCodeOnly={true} aria-label='Flag' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
              </p>
              <p className={`text-sm flex items-center rounded-full ${theme === 'dark' ? 'bg-dark-blue' : 'text-gray-400'} px-1 rounded-full`}>
                {translate('source')}: {post.source}, {post.country}
              </p>
            </div>
          )}
          <div style={{ display: 'flex', alignItems: 'center',whiteSpace: 'nowrap'}}>
                {post.tag2 && post.tag2 !== 'All' && (
                  <p className={`text-sm text-white flex items-center bg-[#031f2d] rounded-full${theme === 'dark' ? 'bg-white' : 'bg-white'} px-1 rounded-full ml-8`}>{post.tag2}</p>
                )}
          </div>
        </div>
    

        <div className="flex justify-end items-center mx-4 mb-2">
          {post.FactCheck?.factuality != null && (
            <p className={`text-sm flex items-center bg-[#031f2d] rounded-full px-1 ml-2 rounded-full ${post.FactCheck.factuality ? 'bg-green-500' : 'bg-red-500'}`}style={{ marginRight: '20px' }}>
              {post.FactCheck.factuality.toString() === 'true' ? 'Verified' : post.FactCheck.factuality.toString() === 'false' ? 'Warning' : 'Non-factual'}
            </p>
          )}
          {post.FactCheck?.factuality == null && (
            <p className={`text-sm flex items-center bg-[#031f2d] rounded-full px-1 ml-2 rounded-full bg-[#efc9d9]`}style={{ marginRight: '20px', whiteSpace:'nowrap' }} >
              Non-factual
            </p>
          )}
        </div>

      </div>
      
      <div className="grid grid-cols-[98%_2%] justify-center">
        <h1 className="text-lg md:text-xl text-gray-900 leading-snug mb-1 font-semibold">{post.title}</h1>
      </div>
      {post.FactCheck?.correction && post.FactCheck.factuality === false && (
        <p className="text-base text-gray-800 italic">
          <span style={{ textDecoration: 'underline' }}>{translate('correction')}:</span> {post.FactCheck.correction.toString()}
        </p>
      )}
      <div className="flex justify-between items-center mx-4 mb-2">
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
        <p className={`text-sm flex items-center rounded-full ${theme === 'dark' ? 'bg-dark-blue' : 'text-gray-400'} px-1 rounded-full`}>
          <FontAwesomeIcon icon={faClock} style={{ marginRight: '4px' }} />
          {calculateTimeDifference(post.createdAt)}
        </p>
      </div>
      {expanded && (
      <div>
        <div>
          {post.FactCheck?.error && post.FactCheck.factuality === false && (
            <>
              <p className="text-base text-gray-800">
                <span style={{ textDecoration: 'underline' }}>{translate('error')}:</span> {post.FactCheck.error.toString()}
              </p>
              <br />
            </>
          )}
          {post.FactCheck?.reasoning && post.FactCheck.factuality === false && (
            <>
              <p className="text-base text-gray-800">
                <span style={{ textDecoration: 'underline' }}>{translate('reasoning')}:</span> {post.FactCheck.reasoning.toString()}
              </p>
              <br />
            </>
          )}
          {evidenceUrl && post.FactCheck.factuality === false && (  
            <>
          <p className="text-base text-gray-800 italic">
            <span style={{ textDecoration: 'underline' }}>
              {translate('evidence')}:
            </span>
            {/* Espace ajouté avant le lien */}
            <a
              href={evidenceUrl}
              style={{
                textDecoration: 'none',
                marginLeft: '5px', // Espace avant le lien
                wordBreak: 'break-word',
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.textDecoration = 'underline';
                e.currentTarget.style.color = '#5555FF'; // Couleur plus claire au survol
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.textDecoration = 'none';
                e.currentTarget.style.color = '#031f2d'; // Couleur originale après le survol
              }}
            >
              {evidenceUrl}
            </a>
          </p>
              <br />
            </>
          )}
          <p className="text-base text-gray-800">
            <span style={{ textDecoration: 'underline' }}>{translate('original article')}:</span> {post.description} [...]
          </p>
                <br />
                
                
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <p className="text-base" style={{lineHeight: '0.5rem'}}>
              <a
                href="#"
                onClick={handleReadArticle}
                style={{
                  textDecoration: 'none',
                  backgroundColor: '#031f2d',
                  color: '#ffffff',
                  display: 'inline-block',
                  border: '1px solid #031f2d',
                  borderRadius: '20px',
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease',
                }
              }
              onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.05)'; }}
              onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}
              >
                <span style={{ padding: '10px 20px', display: 'block', display: 'flex', gap: '10px', marginLeft: 'auto', lineHeight: 'normal', alignItems: 'center' }}>
                  {translate('read original article')}
                </span>
        </a>
      </p>

      <button
        onClick={() => {
          const shareUrl = `https://thebinational.com/news/${post.title}/${post._id}`; // Nouvelle URL
          if (navigator.share) {
            // Utilise l'API Web Share si disponible
            navigator.share({
              url: shareUrl,
            })
              .then(() => console.log('Partage réussi'))
              .catch((error) => console.error('Erreur de partage:', error));
          } else {
            // Fallback pour les navigateurs qui ne supportent pas l'API Web Share
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareUrl)}`;
            window.open(whatsappUrl, '_blank');
          }
        }}
        style={{
          backgroundColor: '#031f2d',
          color: 'white',
          border: 'none',
          borderRadius: '20px',
          padding: '10px 20px',
          cursor: 'pointer',
          marginLeft: '10px', // Marge à gauche pour espacer le bouton
          lineHeight: 'normal' 
        }}
      >
        <FontAwesomeIcon icon={faShareSquare} />
      </button>

      <button
        onClick={(e) => { e.stopPropagation(); copyToClipboard(`https://thebinational.com/news/${post.title}/${post._id}`)}} // Lien à copier
        style={{
          backgroundColor: buttonColor, // Couleur pour le bouton
          color: 'white',
          border: 'none',
          borderRadius: '20px',
          padding: '10px 20px',
          cursor: 'pointer',
          marginLeft: '10px', // Marge à gauche
          lineHeight: 'normal' 
        }}
      >
        <FontAwesomeIcon icon={faCopy} /> {/* Icône de copier-coller */}
      </button>
      </div>

          </div>
        </div>
        
      )}
      {showPopup && (
      <>
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999
          }}
          onClick={() => setShowPopup(false)}
        />
        <div style={{
          position: 'fixed',
          top: '5%',
          left: '5%',
          right: '5%',
          bottom: '5%',
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            padding: '10px 20px',
            borderBottom: '1px solid #eee'
          }}>
           <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ margin: 0 }}>
              {translate('Article from: ')}
            </h2>
            <a
              onClick={() => window.open(post.link, '_blank')}
              style={{
                textDecoration: 'none',
                backgroundColor: '#031f2d',
                color: '#ffffff',
                display: 'inline-block',
                border: '1px solid #031f2d',
                borderRadius: '20px',
                padding: '10px 20px',
                overflow: 'hidden',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                marginLeft: '10px', // Espace entre le texte et le bouton
              }}
            >
              Here
            </a>
          </div>
            <button 
              onClick={() => setShowPopup(false)} 
              style={{ 
                background: 'none', 
                border: 'none', 
                fontSize: '1.5rem', 
                cursor: 'pointer' 
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <iframe
            src={getGoogleTranslateUrl(post.link, language)}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '10px',
            }}
            title="Translated Article"
          />
        </div>
      </>
    )}
      
    </div>
  );
};

export default Post;
