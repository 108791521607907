import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSliders } from '@fortawesome/free-solid-svg-icons';
import './FilterComponent.css';

const FilterComponent = ({
  theme,
  selectedCountries,
  setSelectedCountries,
  language,
  setLanguage,
  onLanguageChange,
  onCountryChange,
  countries,
  languages,
  categories,
  selectedCategories,
  setSelectedCategories
}) => {

  const [isVisible, setIsVisible] = useState(false);
  const [selectAllCountries, setSelectAllCountries] = useState(true);
  const [selectAllCategories, setSelectAllCategories] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [isLarge, setIsLarge] = useState(false);

  useEffect(() => {
    setSelectedCategories(categories.map(category => category.label));
    setSelectedCountries(countries.map(country => country.value));
  }, []);

  useEffect(() => {
    setIsLarge(!isScrolling);
  }, [isScrolling]);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleToggleSelection = (selectedValues, value, setSelectedState, onSelectChange, setSelectAllState) => {
    const isAlreadySelected = selectedValues.includes(value);
    let updatedValues;

    if (isAlreadySelected) {
      updatedValues = selectedValues.filter(item => item !== value);
    } else {
      updatedValues = [...selectedValues, value];
    }

    setSelectedState(updatedValues);
    onSelectChange(updatedValues);

    if (updatedValues.length < selectedValues.length) {
      setSelectAllState(false);
    } else {
      setSelectAllState(true);
    }
  };

  const handleSelectAll = (selectAllState, setSelectedState, allValues, currentValues, setSelectAllState, onSelectChange) => {
    const newSelectAllState = !selectAllState;
    const newValues = newSelectAllState ? allValues : [];
    setSelectedState(newValues);
    onSelectChange(newValues);
    setSelectAllState(newSelectAllState);
  };

  const handleCountryClick = (selectedCountry) => {
    handleToggleSelection(selectedCountries, selectedCountry, setSelectedCountries, onCountryChange, setSelectAllCountries);
  };

  const handleCategoryClick = (selectedCategory) => {
    handleToggleSelection(selectedCategories, selectedCategory, setSelectedCategories, setSelectedCategories, setSelectAllCategories);
  };

  const handleSelectAllCountries = () => {
    handleSelectAll(selectAllCountries, setSelectedCountries, countries.map(country => country.value), selectedCountries, setSelectAllCountries, onCountryChange);
  };

  const handleSelectAllCategories = () => {
    handleSelectAll(selectAllCategories, setSelectedCategories, categories.map(category => category.label), selectedCategories, setSelectAllCategories, setSelectedCategories);
  };

  return (
    <div>
      <button 
        className={`toggle-button ${isLarge ? 'toggle-button-large' : ''}`} 
        onClick={toggleVisibility}
        aria-label={isLarge ? "Large toggle button" : "Toggle button"}
      >
        <FontAwesomeIcon
          icon={isVisible ? faTimes : faSliders}
          size="lg"
        />
        {!isScrolling && <span></span>}
      </button>
      <div className={`filter-container ${isVisible ? 'open' : ''}`}>
      <div className="section" style={{ border: '1px solid #031f2d', borderRadius: '8px', margin: '1px 20px', padding: '10px' }}>
          <div className="mb-2">
            <label className="block text-xs font-bold mb-1" style={{ color: '#031f2d', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '14px' }}>Translate all to:</label>
            <select
              className="py-1 px-2 rounded-md bg-[#fff] text-[#031f2d] border border-[#fff] hover:border-[#fff] focus:outline-none focus:border-[#fff] transition-all duration-300"
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value);
                onLanguageChange(e.target.value);
              }}
              style={{
                fontSize: '14px',
                cursor: 'pointer',
                width: '100%',
                maxWidth: '200px',
                padding: '6px',
                backgroundColor: '#ffffff',
                color:  '#031f2d',
                borderRadius: '8px',
                border: '1px solid #031f2d',
                transition: 'width 0.3s ease-in-out',
              }}
            >
              {languages.map((language) => (
                <option key={language.value} value={language.value}>
                  {language.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="section" style={{ border: '1px solid #031f2d', borderRadius: '8px', margin: '10px 20px', padding: '10px' }}>
          <div className="flex flex-col mb-4">
            <div className="mb-2">
              <label className="block text-xs font-bold mb-1" style={{ color: '#031f2d', fontFamily: 'Arial', fontWeight: 'bold',fontSize: '14px' }}>Source from:</label>
              <div style={{ height: '140px', overflowY: 'scroll' }}> 
                <button
                  onClick={handleSelectAllCountries}
                  className={`py-1 px-2 rounded-md bg-${selectAllCountries ? '031f2d' : 'eff7ff'} text-${selectAllCountries ? 'eff7ff' : '031f2d'} border border-${selectAllCountries ? 'eff7ff' : '031f2d'} border-solid hover:border-eff7ff focus:outline-none focus:border-eff7ff transition-all duration-300`}
                  style={{ 
                    fontSize: '14px', 
                    marginTop: '5px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    backgroundColor: selectAllCountries ? '#031f2d' : '#ffffff',
                    color: selectAllCountries ? '#fff' : '#031f2d',
                    borderRadius: '8px',
                    border: '1px solid #031f2d',
                    padding: '5px 15px',
                    cursor: 'pointer',
                    width: '95%'
                  }}
                >
                  {selectAllCountries ? 'Deselect all' : 'Select all'}
                </button>
  
                {countries.slice().sort((a, b) => a.label.localeCompare(b.label)).map((country) => (
                  <button 
                    key={country.value} 
                    onClick={() => handleCountryClick(country.value)} 
                    className={`country-button py-1 px-2 rounded-md bg-${selectedCountries.includes(country.value) ? '[#031f2d]' : '[#fff]'} text-${selectedCountries.includes(country.value) ? '[#fff]' : '[#031f2d]'} border border-${selectedCountries.includes(country.value) ? '[#fff]' : '[#031f2d]'} hover:border-[#fff] focus:outline-none focus:border-[#fff] transition-all duration-300`} 
                    style={{ 
                      fontSize: '14px', 
                      marginTop: '5px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      backgroundColor: (selectedCountries.includes(country.value) ? '#031f2d' : '#ffffff'),
                      color: selectedCountries.includes(country.value) ? '#fff' : '#031f2d',
                      borderRadius: '8px',
                      border: '1px solid #031f2d',
                      padding: '5px 15px',
                      cursor: 'pointer',
                    }}
                    onMouseEnter={() => setHoveredCountry(country.value)}
                    onMouseLeave={() => setHoveredCountry(null)}
                  >
                    {country.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="section" style={{ border: '1px solid #031f2d', borderRadius: '8px', margin: '10px 20px', padding: '10px' }}>
          <div className="mb-2">
            <label className="block text-xs font-bold mb-1" style={{ color: '#031f2d', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '14px' }}>Adjust to a specific category:</label>
            <div style={{ height: '110px', overflowY: 'scroll' }}>
              <button
                onClick={handleSelectAllCategories}
                className={`py-1 px-2 rounded-md bg-${selectAllCategories ? '031f2d' : 'eff7ff'} text-${selectAllCategories ? 'eff7ff' : '031f2d'} border border-${selectAllCategories ? 'eff7ff' : '031f2d'} border-solid hover:border-eff7ff focus:outline-none focus:border-eff7ff transition-all duration-300`}
                style={{
                  fontSize: '14px',
                  marginTop: '5px',
                  marginLeft: '5px',
                  marginRight: '5px',
                  backgroundColor: selectAllCategories ? '#031f2d' : '#ffffff',
                  color: selectAllCategories ? '#fff' : '#031f2d',
                  borderRadius: '8px',
                  border: '1px solid #031f2d',
                  padding: '5px 15px',
                  cursor: 'pointer',
                  width: '95%'
                }}
              >
                {selectAllCategories ? 'Deselect all' : 'Select all'}
              </button>
              {categories.slice().sort((a, b) => a.label.localeCompare(b.label)).map((category, index) => (
                <button
                  key={index}
                  onClick={() => handleCategoryClick(category.label)} // Pass category.label to handleCategoryClick
                  className={`category-button py-1 px-2 rounded-md bg-${selectedCategories.includes(category.label) ? '[#031f2d]' : '[#fff]'} text-${selectedCategories.includes(category.label) ? '[#fff]' : '[#031f2d]'} border border-${selectedCategories.includes(category.label) ? '[#fff]' : '[#031f2d]'} hover:border-[#fff] focus:outline-none focus:border-[#fff] transition-all duration-300`}
                  style={{
                    fontSize: '14px',
                    marginTop: '5px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    backgroundColor: selectedCategories.includes(category.label) ? '#031f2d' : '#ffffff',
                    color: selectedCategories.includes(category.label) ? '#fff' : '#031f2d',
                    borderRadius: '8px',
                    border: '1px solid #031f2d',
                    padding: '5px 15px',
                    cursor: 'pointer',
                  }}
                >
                  {category.label}
                </button>
              ))}
            </div>
          </div>

        </div>
        <div style={{ textAlign: 'center' }}>
    <label className="inline-block text-xs font-bold mb-1" style={{ color: '#031f2d', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '14px', marginRight: '10px' }}>
        <a href="mailto:contact@k-software.fr" style={{ textDecoration: 'none', color: 'inherit' }}>Contact us</a>
    </label>
    <span style={{ color: '#031f2d', fontSize: '14px', marginRight: '10px' }}>|</span>
    <label className="inline-block text-xs font-bold mb-1" style={{ color: '#031f2d', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '14px', marginRight: '10px' }}>
        <a href="mailto:contact@k-software.fr" style={{ textDecoration: 'none', color: 'inherit' }}>We are hiring &#9829;</a>
    </label>
    <span style={{ color: '#031f2d', fontSize: '14px', marginRight: '10px' }}>|</span>
    <label className="inline-block text-xs font-bold mb-1" style={{ color: '#031f2d', fontFamily: 'Arial', fontWeight: 'bold', fontSize: '14px' }}>
        <a href={language === 'French' ? 'https://www.k-software.fr' : 'https://k-software.fr/'} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>About us</a>
    </label>
    <div style={{ marginTop: '5px' }}>
        <a href={language === 'French' ? 'https://www.k-software.fr' : 'https://k-software.fr/'} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#031f2d', fontFamily: 'Arial', fontSize: '12px' }}>
            <span className="text-xs">Copyright 2024 @ k-software.fr</span>
        </a>
   
</div>


</div>


      </div>

    </div>
  );
};

export default FilterComponent;
